import axios from './axios';

export default {
  all: async () => {
    let url = `${process.env.REACT_APP_API_URL}/reservations`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  eventService: async (eventId, data) => {
    let url = `${process.env.REACT_APP_API_URL}/reservations/event/${eventId}/${data}`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  current: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/reservations/${id}`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  pickDate: async (id, date) => {
    let url = `${process.env.REACT_APP_API_URL}/reservations/${id}/${date}`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  pickDateEvent: async (eventId, data, serviceId) => {
    let url = `${process.env.REACT_APP_API_URL}/reservations/event/${eventId}/${data}/${serviceId}`;
    console.log('Generated URL:', url);
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  saveReservation: async (body) => {
    let url = `${process.env.REACT_APP_API_URL}/reservations/${body.service_id}`;
    let token = localStorage.getItem('auth');

    return await axios.post(
      url,
      { ...body },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  remove: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/reservations/${id}`;
    let token = localStorage.getItem('auth');

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
