import axios from './axios';

import auth from '../resources/Auth';

export default {
  get: async (id = undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/shopservices/event/eventAll${id ? `/${id}` : ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
  },

  find: async (id) => {
    if (!id) throw new Error("L'ID dell'evento è richiesto.");
    let url = `${process.env.REACT_APP_API_URL}/shopservices/event/findEventOne/${id}`;
    console.log('Request URL:', url);

    try {
      return await axios.get(url, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
    } catch (error) {
      console.error('API Call Error:', error.response || error.message || error);
      throw error;
    }
  },

  create: async (shopservice) => {
    let url = `${process.env.REACT_APP_API_URL}/shopservices`;

    return await axios.post(url, shopservice, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
  },
};
