import React, { Component } from 'react';
import moment from 'moment';
import { closeOutline as close, trashBin as trash } from 'ionicons/icons';
import {
  IonItem,
  IonTitle,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonText,
  IonList,
  IonBackButton,
  IonModal,
  IonButton,
  IonIcon,
  withIonLifeCycle,
} from '@ionic/react';

import SystemResource from '../resources/System';
import HeaderButtons from '../components/HeaderButtons';
import ReservationsService from '../services/reservations';

class ReservationsPage extends Component {
  constructor(props) {
    super(props);

    this.loader = React.createRef();

    this.state = {
      reservations: [],
      isModalOpen: false,
      selectedService: null,
      isCancellable: false,
    };
  }

  async ionViewWillEnter() {
    await this.getReservations();
  }

  async getReservations() {
    if (!!this.props.loader) this.props.loader.current.show();
    try {
      SystemResource.checkVersion();
      const reservations = await ReservationsService.all();

      await this.setState({ reservations: reservations.data });
    } catch (error) {
      console.log(error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  checkCancellationAvailability(res) {
    const reservationDate = moment(res.day);
    const now = moment().startOf('day');
    const maxCancellationDays = res.service.booking_cancellations;
    const cancellationLimit = reservationDate.clone().subtract(maxCancellationDays, 'days');
    const isCancellable = now.isSameOrBefore(cancellationLimit);

    this.setState({ isCancellable });
  }

  async submitHandler(that, id) {
    try {
      await ReservationsService.remove(id);
      await that.props.toast.current.add({ message: `Prenotaziona cancellata correttamente.` });

      await that.setState({ selectedService: null });
      await that.setState({ isModalOpen: false });

      await that.props.history.replace('/app/reservations');
    } catch (e) {
      console.log(e);
      that.props.toast.current.add({
        message: `Problema nella cancellazione della prenotazione.`,
        color: 'danger',
      });

      delete that.props.toast.current.onDidDismiss;
    } finally {
      await this.getReservations();
    }
  }

  render() {
    const { reservations, isModalOpen, selectedService, isCancellable } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/home" />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Prenotazione</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonList>
            <ion-list-header>
              <IonLabel className="ion-text-uppercase">
                <b>Le tue prenotazioni</b>
              </IonLabel>
            </ion-list-header>

            {reservations[0] ? (
              reservations.map((res, i) => {
                let date = moment(res.day).clone().format('DD/MM/YY');
                let start = res.start_appointment.slice(0, 5);

                return (
                  <IonItem
                    key={res.id}
                    color={i % 2 === 0 && 'light'}
                    onClick={() => {
                      this.setState({ isModalOpen: true });
                      this.setState({ selectedService: res });
                      this.checkCancellationAvailability(res);
                    }}
                  >
                    <IonText>
                      <h4>{res.service.name}</h4>
                      <p>
                        {date} - {start}
                      </p>
                    </IonText>
                  </IonItem>
                );
              })
            ) : (
              <IonItem>
                <IonLabel>Non sono presenti prenotazioni</IonLabel>
              </IonItem>
            )}
          </IonList>

          {isModalOpen && !!selectedService && (
            <IonModal isOpen={isModalOpen}>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton
                      onClick={() => {
                        this.setState({ isModalOpen: false });
                        this.setState({ selectedService: '' });
                      }}
                    >
                      <IonIcon icon={close} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Riepilogo</IonTitle>
                </IonToolbar>
              </IonHeader>

              <IonContent fullscreen>
                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>Prenotazione</b>
                    </IonLabel>
                  </ion-item-divider>

                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Servizio:</h4>
                      <p>{selectedService.service.name}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Giorno:</h4>
                      <p>{moment(selectedService.day).format('dddd | DD MMMM YYYY')}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Ora inizio:</h4>
                      <p>{selectedService.start_appointment}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Ora fine:</h4>
                      <p>{selectedService.end_appointment}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Durata:</h4>
                      <p>{selectedService.service.duration} min</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Prezzo:</h4>
                      <p>{selectedService.service.price} €</p>
                    </IonLabel>
                  </IonItem>
                </ion-item-group>

                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>Note</b>
                    </IonLabel>
                  </ion-item-divider>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Note al farmacista:</h4>
                      {selectedService.note ? (
                        <p dangerouslySetInnerHTML={{ __html: selectedService.note }}></p>
                      ) : (
                        <p>Nessuna nota</p>
                      )}
                    </IonLabel>
                  </IonItem>
                </ion-item-group>

                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>Azione</b>
                    </IonLabel>
                  </ion-item-divider>
                  <IonItem lines="none">
                    <IonLabel>
                      {isCancellable ? (
                        <IonButton
                          id="delete"
                          expand="full"
                          color="danger"
                          onClick={() => this.submitHandler(this, selectedService.id)}
                        >
                          <IonIcon slot="start" icon={trash} />
                          Cancella prenotazione
                        </IonButton>
                      ) : (
                        <IonItem className="ion-text-wrap">Non è più possibile cancellare questa prenotazione</IonItem>
                      )}
                    </IonLabel>
                  </IonItem>
                </ion-item-group>
              </IonContent>
            </IonModal>
          )}
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ReservationsPage);
